<template>
  <b-card v-if="Object.keys(product).length" class="blog-edit-wrapper">
    <!-- form -->
    <b-form>
      <b-row class="mb-2">
        <h6 class="section-label mx-1">Edit Product</h6>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group label="Title" label-for="product-title" class="mb-2">
            <b-form-input id="product-title" v-model="product.title" />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Product Type" label-for="product-prod_type" class="mb-2">
            <b-form-select id="product-prod_type" :options="productOptions" v-model="product.prod_type" />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Meta Title" label-for="product-meta_title" class="mb-2">
            <b-form-input id="product-meta_title" v-model="product.meta_title" />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="product.prod_type == 0">
          <b-form-group label="SKU" label-for="product-sku" class="mb-2">
            <b-form-input id="product-sku" v-model="product.sku" />
          </b-form-group>
        </b-col>

        <b-col md="6" v-if="product.prod_type == 0">
          <b-form-group label="Price" label-for="product-price" class="mb-2">
            <b-form-input id="product-price" v-model="product.price" type="number" step="0.01" min="0.00" max="1000000.00" />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Category" label-for="product-category" class="mb-2">
            <v-select id="product-category" v-model="product.categories" multiple :options="categoryOption" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Allergies" label-for="product-allergies" class="mb-2">
            <v-select id="product-allergies" v-model="allergies" multiple :options="allergyOption" />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="product.prod_type == 0">
          <b-form-group label="Attributes" label-for="product-attributes" class="mb-2">
            <v-select v-model="attributes" id="product-attributes" multiple :options="attributesOption" />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Status" label-for="product-status" class="mb-2">
            <b-form-select v-model="product.is_deleted" id="product-status" :options="statusOption" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Apply Global Discount" label-for="product-discountable" class="mb-2">
            <b-form-select id="product-discountable" :options="discountOption" v-model="product.discountable" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Default VAT" label-for="product-vat_id" class="mb-2">
            <b-form-select id="product-vat_id" :options="vatOption" v-model="product.vat_id" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Order" label-for="product-order" class="mb-2">
            <b-form-input id="product-prod_order" v-model="product.prod_order" type="number" step="1" min="0" max="1000000" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Summary" label-for="product-summary" class="mb-2">
            <quill-editor id="product-summary" v-model="product.summary" />
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-2">
          <div class="border rounded p-2">
            <h4 class="mb-1">Featured Image</h4>
            <b-media no-body vertical-align="center" class="flex-column flex-md-row">

              <b-media-aside v-if="product.image">
                <b-img ref="refPreviewEl" :src="product.image" height="110" width="170" class="rounded mr-2 mb-1 mb-md-0" />
              </b-media-aside>
              <b-media-body>
                <div class="d-inline-block">
                  <input type="file" accept=".jpg, .png" size="300000" placeholder="Choose file" ref="refInputEl" @input="inputImageRenderer" @change="toDataURL"></input>

                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>

      </b-row>
      <template v-if="product.prod_type == 1">
        <b-row>
          <h5 class="section-label mx-1 mb-2">Variations</h5>
          <span @click="addVariant()" class="btn btn-primary btn-sm">ADD VARIANT</span>
        </b-row>
        <b-row v-for="(variant, variantIndex) in product.options" :key="'variant-' + variantIndex">
          <b-col cols="12" class="mb-2">
            <h6 class="section-label mx-1 mt-1">Variant #{{ variantIndex }} - <span class="text-danger" @click="removeVariant(variantIndex)">Remove Variant</span></h6>
          </b-col>
          <b-col md="4">
            <b-form-group label="Name" :label-for="'product-variant-name' + variantIndex" class="mb-2">
              <b-form-input :id="'product-variant-name' + variantIndex" v-model="product.options[variantIndex].name" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="SKU" :label-for="'product-variant-sku' + variantIndex" class="mb-2">
              <b-form-input :id="'product-variant-sku' + variantIndex" v-model="product.options[variantIndex].sku" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Price" :label-for="'product-variant-price' + variantIndex" class="mb-2">
              <b-form-input :id="'product-variant-price' + variantIndex" v-model="product.options[variantIndex].price" type="number" step="0.01" min="0.00" max="1000000.00" />
            </b-form-group>
          </b-col>


          <b-col md="12">
            <b-form-group label="Attributes" :label-for="'product-variant-attributes' + variantIndex" class="mb-2">
              <v-select v-model="product.options[variantIndex].attributes" :id="'product-attributes' + variantIndex" multiple :options="attributesOption" />
            </b-form-group>
          </b-col>


          <b-col cols="12">
            <b-form-group label="Summary" :label-for="'product-variant-summary' + variantIndex" class="mb-2">
              <quill-editor :id="'product-variant-summary' + variantIndex" v-model="product.options[variantIndex].summary" />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col cols="12" class="mt-50">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" @click="saveProduct()">
            Save Changes
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="secondary" to="/takeaway/products/">
            Cancel
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" class="pull-right ml-1" @click="deleteProduct(id)">
            Remove Product
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormNumber,
  BImg,
  BFormFile,
  BLink,
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

import _ from "lodash";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const aws = require('aws-sdk');

aws.config.update({
  accessKeyId: 'AKIARLZ53ZXES6XSB6UR',
  secretAccessKey: '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'
});

export const s3 = new aws.S3({
  signatureVersion: 'v4',
  region: 'eu-west-1'
});

import axios from "axios"
export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BFormNumber,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },
  props: ["id"],
  directives: {
    Ripple,
  },
  data() {
    return {
      product: {},
      allergies: [],
      attributes: [],
      vat: [],
      categoryOption: [],
      allergyOption: [],
      attributesOption: [],
      statusOption: [
        { value: 0, text: "Published" },
        { value: 2, text: "Draft" },
      ],
      discountOption: [
        { value: 1, text: "Yes" },
        { value: 0, text: "No" },
      ],
      vatOption: [],
      productOptions: [
        { value: 0, text: "Simple" },
        { value: 1, text: "Variable" },
      ],
    };
  },
  methods: {
    addVariant() {
      if (this.product.options) {
        this.product.options.push({
          name: '',
          sku: '',
          price: '',
          attributes: [],
          description: ''
        })
      } else {
        this.product.options = []
        this.product.options.push({
          name: '',
          sku: '',
          price: '',
          attributes: [],
          description: ''
        })
      }
    },
    removeVariant(index) {
      this.product.options.splice(index, 1)
    },
    async toDataURL() {
      let file = this.$refs.refInputEl.files[0];
      let key = 'tenant-' + JSON.parse(localStorage.getItem('userData')).tenant_id + '/products/' + Date.now() + '-' + file.name;

      const params = {
        Bucket: 'tenants-app',
        Key: key,
        Expires: 10,
        ContentType: file.type
      };

      const url = s3.getSignedUrl('putObject', params);
      return axios
        .put(url, file, {
          headers: {
            'Content-Type': file.type
          }
        })
        .then(result => {
          const bucketUrl = decodeURIComponent(result.request.responseURL).split(key)[0];

          this.product.image = bucketUrl + key;

          return result;
        })
        .catch(err => {
          // TODO: error handling
          console.error(err);
        });
    },
    deleteProduct(id) {
      useJwt
        .delete_product(id)
        .then(() => {
          this.$forceUpdate();
          this.$router.push("/takeaway/products");
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't remove your product data from the server.`,
            },
          });
        });
    },
    async saveProduct() {
      await useJwt
        .delete_product_categories(this.id)
        .then(() => {
          this.$forceUpdate();
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't remove your product data from the server.`,
            },
          });
        });
      await useJwt
        .delete_product_allergy(this.id)
        .then(() => {
          this.$forceUpdate();
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't remove your product data from the server.`,
            },
          });
        });
      await useJwt
        .delete_product_attribute(this.id)
        .then(() => {
          this.$forceUpdate();
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't remove your product data from the server.`,
            },
          });
        });

      let attributes = JSON.parse(localStorage.getItem("userAttributes"));
      if (this.product.prod_type == 1) {
        if (attributes.length) {
          if (this.product.options) {
            for (let x = 0; x < this.product.options.length; x++) {
              if (this.product.options[x].attributes) {
                for (let z = 0; z < this.product.options[x].attributes.length; z++) {
                  for (let y = 0; y < attributes.length; y++) {
                    if (this.product.options[x].attributes[z].value == attributes[y].id) {
                      this.product.options[x].attributes[z] = attributes[y];
                      break;
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (!this.product.prod_type) { this.product.prod_type = 0; }
      if (this.product.prod_type == 0) { this.product.options = []; }

      await useJwt
        .patch_product(this.id, {
          title: this.product.title,
          metaTitle: this.product.metaTitle,
          slug: this.product.slug,
          summary: this.product.summary,
          sku: this.product.sku,
          price: parseFloat(this.product.price).toFixed(2),
          is_deleted: this.product.is_deleted,
          discountable: this.product.discountable,
          image: this.product.image,
          vat_id: this.product.vat_id,
          prod_order: this.product.prod_order,
          prod_type: this.product.prod_type,
          options: JSON.stringify(this.product.options)
        })
        .then(() => {
          this.$forceUpdate();
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't remove your product data from the server.`,
            },
          });
        });
      if (this.allergies.length) {
        for (let x = 0; x < this.allergies.length; x++) {
          await useJwt
            .post_product_allergy('create', {
              product_id: this.id,
              allergy_id: this.allergies[x].value,

            })
            .then(() => {
              this.$forceUpdate();
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "CropIcon",
                  variant: "danger",
                  text: `We couldn't remove your product data from the server.`,
                },
              });
            });
        }
      }
      if (this.product.categories.length) {
        for (let x = 0; x < this.product.categories.length; x++) {
          await useJwt
            .post_product_categories('create', {
              product_id: this.id,
              category_id: this.product.categories[x].value,

            })
            .then(() => {
              this.$forceUpdate();
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "CropIcon",
                  variant: "danger",
                  text: `We couldn't remove your product data from the server.`,
                },
              });
            });
        }
      }
      if (this.attributes.length) {
        for (let x = 0; x < this.attributes.length; x++) {
          await useJwt
            .post_product_attributes('create', {
              product_id: this.id,
              attribute_id: this.attributes[x].value,

            })
            .then(() => {
              this.$forceUpdate();
            })
            .catch((e) => {
              console.error(e)
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "CropIcon",
                  variant: "danger",
                  text: `We couldn't remove your product data from the server.`,
                },
              });
            });
        }
      }
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Product saved`,
          icon: "CoffeeIcon",
          variant: "success",
          text: `You have successfully saved product.`,
        },
      });
      this.$router.push("/takeaway/products");
    }
  },
  async created() {
    await useJwt
      .get_product(this.id)
      .then((response) => {
        this.product = response.data.data;
console.log(response.data.data.is_deleted )
        if (response.data.data.is_deleted == 1 || response.data.data.is_deleted == null) this.$router.push("/takeaway/products");
        this.product.options = JSON.parse(this.product.options);
        if (this.product.categories) {
          this.product.cats = this.product.categories.split(",");
        }
        this.product.categories = [];
        if (this.product.options) {
          for (let x = 0; x < this.product.options.length; x++) {
            for (let y = 0; y < this.product.options[x].attributes.length; y++) {
              this.product.options[x].attributes[y].value = this.product.options[x].attributes[y].id
              this.product.options[x].attributes[y].label = this.product.options[x].attributes[y].title
              delete this.product.options[x].attributes[y].id;
              delete this.product.options[x].attributes[y].max_select;
              delete this.product.options[x].attributes[y].min_select;
              delete this.product.options[x].attributes[y].option_values;
              delete this.product.options[x].attributes[y].required;
              delete this.product.options[x].attributes[y].content;
              delete this.product.options[x].attributes[y].title;
              delete this.product.options[x].attributes[y].type;
            }
          }
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your products data from the server.`,
          },
        });
      });

    await useJwt
      .get_product_allergies(this.id)
      .then((response) => {
        this.product.allergies_raw = response.data.data;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your products data from the server.`,
          },
        });
      });
    await useJwt
      .get_allergies(this.id)
      .then((response) => {
        localStorage.setItem(
          "userAllergies",
          JSON.stringify(response.data.data)
        );
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your products data from the server.`,
          },
        });
      });
    await useJwt
      .get_vat()
      .then((response) => {
        localStorage.setItem("userVat", JSON.stringify(response.data.data));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your products data from the server.`,
          },
        });
      });
    await useJwt
      .list_categories()
      .then((response) => {
        localStorage.setItem("userCategories", JSON.stringify(response.data.data));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your products data from the server.`,
          },
        });
      });

    await useJwt
      .get_product_attributes(this.id)
      .then((response) => {
        this.product.attributes_raw = response.data.data;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your products data from the server.`,
          },
        });
      });
    await useJwt
      .get_attributes(this.id)
      .then((response) => {
        localStorage.setItem(
          "userAttributes",
          JSON.stringify(response.data.data)
        );
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your products data from the server.`,
          },
        });
      });

    let allergies = JSON.parse(localStorage.getItem("userAllergies"));
    if (allergies.length) {
      this.allergyOption = [];
      for (let x = 0; x < allergies.length; x++) {
        this.allergyOption.push({
          value: allergies[x].id,
          label: allergies[x].title,
        });
      }
    }
    let vat = JSON.parse(localStorage.getItem("userVat"));
    if (vat.length) {
      this.vatOption = [];
      for (let x = 0; x < vat.length; x++) {
        this.vatOption.push({
          value: vat[x].id,
          text: vat[x].amount + ' - ' + vat[x].title,
        });
      }
    }
    let attributes = JSON.parse(localStorage.getItem("userAttributes"));
    if (attributes.length) {
      this.attributesOption = [];
      for (let x = 0; x < attributes.length; x++) {
        this.attributesOption.push({
          value: attributes[x].id,
          label: attributes[x].title,
        });
      }
    }

    if (this.product.attributes_raw.length) {
      for (let x = 0; x < this.product.attributes_raw.length; x++) {
        this.attributes.push({
          value: this.product.attributes_raw[x].attribute_id,
          label: this.product.attributes_raw[x].attribute_title,
        });
      }
      delete this.product.attributes_raw;
    }
    if (this.product.allergies_raw.length) {
      for (let x = 0; x < this.product.allergies_raw.length; x++) {
        this.allergies.push({
          value: this.product.allergies_raw[x].allergy_id,
          label: this.product.allergies_raw[x].allergy_name,
        });
      }
      delete this.product.allergies_raw;
    }

    let cats = JSON.parse(localStorage.getItem("userCategories"));
    for (let x = 0; x < cats.length; x++) {
      this.categoryOption.push({
        value: cats[x].id,
        label: cats[x].title,
      });
    }

    if (this.product.cats) {
      for (let x = 0; x < this.product.cats.length; x++) {
        _.find(this.categoryOption, (o) => {
          if (o.value == this.product.cats[x]) {
            return this.product.categories.push(o);
          }
        });
      }
    }
  },
  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
